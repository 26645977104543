/*
 * 업무구분 : 고객관리
 * 화면 명  : MSPCM150M
 * 화면 설명: 1건고객
 * 접근권한 : FC, 지점장 
 * 작 성 일 : 2022.12.06
 * 작 성 자 : 서영화 
 */

<template>
  <ur-page-container title="1건고객" @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPCM151P" @on-scroll-bottom="fn_LoadingData" 
                     class="msp" :show-title="true" type="subpage" action-type="search" :topButton="true">
    
    <template #frame-header-fixed>
      <!-- 검색결과 영역 start-->
      <ur-box-container  v-if="isSearched && !isCleared" alignV="start"  direction="column" class="search-result-box bd-b-Ty1 bd-T-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <template v-for="(item, idx) in searchedItems">
            <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenMSPCM151P" :idx="item.idx" :key="idx" >
              <!-- 검색결과 조건이름 -->
              <span class="tit" v-if="!$bizUtil.isEmpty(item.title)">{{ item.title }}</span>
              <!-- 검색결과 조건값 -->
              <span class="txt">{{ item.text }}</span> 
            </mo-tab-label>
          </template>
        </mo-tab-box>
      </ur-box-container>
      <!-- 검색결과 영역 end-->
      <!-- 데이터개수 및 체크박스 영역 start -->
      <ur-box-container alignV="start" componentid="ur-box-container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isCheckAll" @click="fn_ClickCheckAll" :disabled="isDisabledCheck || isNoData" size="small"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <!-- 전체 데이터 개수  -->
              <span v-if="checkedCount <= 0" class="sum" >총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
              <!-- 선택된 데이터 개수 -->
              <span v-else class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- 데이터개수 및 체크박스 영역 end -->
    </template>

    <!-- Content영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isNoData" alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-list">
        <!-- 조회결과 리스트 -->
        <!-- ur-box-container class="pb60" 이 부분 추가 : 체크했을 때, bottomsheet로 데이터가 가려서 필요  -->
        <ur-box-container class="pb60"> 
          <mo-list :list-data="cmoneCustListVO">
            <template #list-item="{ index, item }">
              <mo-list-item class="pt5" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/> 
                <div class="list-item__contents pb10">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <!-- 고객카드 없음 240129 추가 -->
                    <span class="name" style="display: flex; padding-right: 18px;">
                      <span class="txtSkip" @click.stop="fn_OpenCustInfoBs(item)">
                        {{ item.custNm }}
                      </span>
                      <span :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" />
                    </span>
                    <!-- 고객카드 없음 240129 추가 -->
                    <!-- 관심구분 -->
                    <mo-badge class="badge-sample-badge lightblue sm" text="본인" shape="status">{{ item.inqrScNm }}</mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 성별 -->
                    <span class="crTy-bk1">{{ item.sxdsCdNm }}</span><em>|</em>
                    <!-- 만나이 -->
                    <span class="crTy-bk1">만{{ item.age }}세</span><em>|</em>
                    <!-- 계약일자 -->
                    <span><span class="crTy-bk7 fs14rem mr10">계약일자</span><span class="crTy-bk1">{{ item.contYmd }}</span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="full fexTy5">
                      <!-- 상품구분 -->
                      <span class="crTy-bk1">{{ item.prdtScNm }}</span><em>|</em>
                      <!-- 상품명 -->
                      <span class="crTy-bk1 txtSkip flex-1">{{ item.prdtNm }}</span>
                    </span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 월남 보험료(원) -->
                    <span><span class="crTy-bk7 fs14rem mr10">월납</span><span class="crTy-bk1">{{ item.mntPadPrm }} 원</span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 주피보험자 -->
                    <span><span class="crTy-bk7 fs14rem mr10">주피보험자</span><span class="crTy-bk1">{{ item.mnisdNm }}</span></span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>  
        </ur-box-container>
      </ur-box-container>

      <!-- NoData 영역 start -->
      <ur-box-container v-else alignV="start" componentid="ur-box-container_003" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 end -->
      
    </ur-box-container>
    <!-- Content영역 end -->

  </ur-page-container>
</template>

<script>

import moment      from 'moment'
import Msg         from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants'
import MSPCM151P   from '@/ui/cm/MSPCM151P'              // 1건고객 고객검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  
  name: 'MSPCM150M', 
  screenId: 'MSPCM150M', 
  mixins: [Screen],
  components: {    
    MSPCM151P,
  },//componets,

  props:{},//props

  data () {
    return {
      
      title: '1건고객',

      lv_inputParam: {},                            // 서비스호출 파라미터 

      /**
       * 사용자 정보 변수
       */
      userInfo: {},                                 // 조직 및 컨설턴트 정보
      isMngr: false,                                // 지점장 여부
      pOrgDataMSPCM150M: {},                        // 최근 조회조건 데이터 

      /**
       * 화면 변수
       */
      isNoData: false,                              
      isLoading: false,

      /**
       * 더보기 key 변수 
       */ 
      stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], 
      pageRowCnt: '20',         
      stndKeyVal: 'start',

      /**
       * 체크박스 변수
       */
      isCheckAll: false,                           // 전체체크 여부
      isDisabledCheck: false,                      // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,                               // 전체 고객수 
      selectedItems: [],                           // 선택된 고객 리스트 

      /**
       * 조회 변수
       */ 
      cmoneCustListVO: [],                          // 1건고객 조회 데이터 
      searchedCustNm: '',                           // 고객명
      inqrScCd: cmConstants.ST_INQR_SC_CD[0].value, // 관심고객구분 
      custScCd: cmConstants.SM_CUST_SC_CD[0].value, // 고객구분
      prcd: cmConstants.SM_PRDT_CD[0].value,        // 상품 

      /**
       *  @notice 이벤트 시작일/종료일이 없으면 데이터 조회가 불가하다.
       *          AS-IS에서 최대 3개월전까지 조회 가능하여, [ 3개월 전부터 당일까지 ]로 설정하여 조회한다.
       */ 
      evtStrYmd: this.$bizUtil.getDateToString(new Date(moment(this.nowDate).add(-3, 'months')), '-'),  // 이벤트 시작일
      evtEndYmd: this.$bizUtil.getDateToString(new Date(), '-'),                                        // 이벤트 종료일
      
      searchedItems: {},                           // 검색결과 데이터 

      /**
       * 제어 변수 
       */ 
      isSearched: false,                           // 조회이력 저장 변수 
      isCleared: true,                             // 조회조건 초기화 여부 
      
      /** 
       * 팝업 연동
       */
      popup151: {},                                // MSPCM151P 1건고객 고객검색

    }
  },//data

  created () {
     
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

    this.fn_Submit()
    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  beforeDestroy () { 
    
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      //backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy

  watch: {

    /**
     * 전체 체크여부를 확인한다.
     */
    checkedCount(){
      let cnt = this.cmoneCustListVO.length 
      if(this.checkedCount == cnt && this.cmoneCustListVO.length != 0){ 
        this.isCheckAll  = true
      }else{
        this.isCheckAll  = false
      }
    },

  },//watch

  computed: {
    
    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.cmoneCustListVO.filter(item => item.checked).length
    },

    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.cmoneCustListVO.filter(item => item.checked)
    },

  },//computed

  methods: {

    fn_OnClickListItem(index) {
      
      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },

    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){
      
      this.pOrgDataMSPCM150M = {}                                   
      this.searchedCustNm    = ''                                    
      this.inqrScCd          = cmConstants.ST_INQR_SC_CD[0].value   
      this.custScCd          = cmConstants.SM_CUST_SC_CD[0].value   
      this.prcd              = cmConstants.SM_PRDT_CD[0].value      
      this.isSearched        = false                                
      this.searchedItems     = {}    

    },

    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },

    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },

    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit(){

      // 초기화  
      this.isNoData    = false
      this.totalCount  = 0  
      this.stndKeyVal  = 'start'               
      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] 
      this.cmoneCustListVO.splice(0)

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.cnsltNo  = this.userInfo.userId          // 컨설턴트번호
      this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo      // 소속지역단조직번호
      this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo   // 소속지점조직번호
      this.lv_inputParam.hofOrgNo = this.userInfo.onpstHofOrgNo   // 소속사업부조직번호

      if(this.pOrgDataMSPCM150M.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화한다.
        if(this.userInfo.userId !== this.pOrgDataMSPCM150M.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.cnsltNo  = this.pOrgDataMSPCM150M.cnslt.key 
        this.lv_inputParam.dofOrgNo = this.pOrgDataMSPCM150M.dofOrg.key 
        this.lv_inputParam.fofOrgNo = this.pOrgDataMSPCM150M.fofOrg.key 
        this.lv_inputParam.hofOrgNo = this.pOrgDataMSPCM150M.hofOrg.key 

      }else{
        this.isDisabledCheck = false
      } 

      // 조회조건 세팅
      this.lv_inputParam.inqrScCd  = this.inqrScCd.trim()      
      this.lv_inputParam.custScCd  = this.custScCd.trim()     
      this.lv_inputParam.custNm    = this.searchedCustNm      
      this.lv_inputParam.prcd      = this.prcd.trim()         
      this.lv_inputParam.evtStrYmd = this.evtStrYmd.replace(/-/gi, '').substring(0, 8)
      this.lv_inputParam.evtEndYmd = this.evtEndYmd.replace(/-/gi, '').substring(0, 8) 

      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // post 호출 전 Progress 타입 설정.

      // 서비스 호출
      this.fn_GetService() 

    },

    /**
     * @description 1건고객 목록 조회 서비스 호출 후, 콜백 데이터를 처리한다.
     * @callback    function(response) 
     */
    fn_GetService() {

      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM45S1'
      // const trnstId = 'txMSPCM45S1'
      const trnstId = 'txTSSCM48S2'
      const auth    = 'S'

      let lv_Vm   = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseList = response.body.cmoneCustListVO;   // 1건고객 리스트 

            if(responseList !== null){

              // 1건고객 조회 데이터 가공 
              responseList.forEach((item, index) => {

                item.contYmd   = lv_Vm.$commonUtil.dateDashFormat(item.contYmd)     // 계약일자
                item.prdtScNm  = lv_Vm.$commonUtil.ellipsisText(item.prdtScNm, 5)   // 상품구분
                item.mntPadPrm = lv_Vm.$commonUtil.numberWithCommas(item.mntPadPrm) // 월납보험료
                
                lv_Vm.$set(responseList[index], 'checked', false)                   // 선택(체크박스) 여부 
                
              })

              // 더보기 데이터 저장 
              if(lv_Vm.stndKeyVal !== 'start'){
                responseList.forEach((item) => { lv_Vm.cmoneCustListVO.push(item) })
              }else{
                lv_Vm.cmoneCustListVO = responseList // 1 page
              }

              // 더보기 키값
              lv_Vm.stndKeyVal = response.trnstComm.stndKeyList    
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_Vm.stndKeyVal = 'end'    // 다음페이지 데이터 존재안함(마지막 페이지)
              }else{
                lv_Vm.stndKeyVal = 'more'   // 다음페이지 데이터 존재함
              }

              // 조회데이터 총 개수 
              lv_Vm.totalCount = lv_Vm.cmoneCustListVO.length

            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false

          // 데이터 유무 
          if (lv_Vm.cmoneCustListVO.length === 0) {
            lv_Vm.isNoData = true    
          } else {
            lv_Vm.isNoData = false  
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })

    },

    /**
     * @description 조회목록을 전체선택 한다. 
     */
    fn_ClickCheckAll(){

      this.$nextTick(() =>{

        if(this.cmoneCustListVO.length > 0){
          this.cmoneCustListVO.forEach((item, index) => {
            if(this.isCheckAll){
              this.cmoneCustListVO[index].checked = true
            }else{
              this.cmoneCustListVO[index].checked = false
            }
          })

          this.selectedItems = [] // 초기화
          this.selectedItems = this.checkedList

          // BottomSheet 제어 
          this.fn_ControlBottomSheet()
        }
      })

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      this.selectedItems = [] // 초기화
      this.selectedItems = this.checkedList

      // BottomSheet 제어 
      this.fn_ControlBottomSheet()

    },

    /**
     * @description BottomSheet를 제어한다.
     *              조회목록을 부분/전체선택하면 노출된다.
     */
    fn_ControlBottomSheet() {
      this.$cmUtil.setSelectedCustList(this, this.checkedList)
    },

    /**
     * @description 데이터를 로딩한다.
     */
    fn_LoadingData(){

      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isCheckAll && this.stndKeyVal !== 'end') this.isCheckAll = false
        
        window.vue.getStore('progress').dispatch('PART') // post 호출 전 Progress 타입 설정.

        this.fn_GetService()
      }

    },


    /**
     * @description 1건고객 고객검색 팝업을 제어한다.
     */
    fn_OpenMSPCM151P(){

      this.popup151 = this.$bottomModal.open(MSPCM151P, {
        properties: {
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                     // 지점장 여부
          pOrgData: this.pOrgDataMSPCM150M,         // 최근 조직 및 컨설턴트 
          pSelectedItems: this.searchedItems,       // 조회조건 결과 
          pSearchedCustNm: this.searchedCustNm,     // 고객명
          pInqrScCd: this.inqrScCd,                 // 관심고객구분
          pSmCustScCd: this.custScCd,               // 고객구분
          pSmPrcd: this.prcd,                       // 상품
        },

        listeners: {
          searchPopup: (pData) => {
             // 모달 닫기
            this.$bottomModal.close(this.popup151)
            
            this.searchedCustNm    = pData.rtnData.searchedCustNm 
            this.inqrScCd          = pData.rtnData.inqrScCd,       
            this.custScCd          = pData.rtnData.smCustScCd
            this.prcd              = pData.rtnData.smPrcd         
            this.pOrgDataMSPCM150M = pData.rtnData.pOrgData      
            this.searchedItems     = pData.rtnData.selectedItems 
            this.isCleared         = pData.rtnData.isCleared     
            this.isSearched        = true                        

            // 1건고객 조회 
            this.fn_Submit()

          }
        }
      })

    },

    /**
     * @description 고객명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 1건고객 데이터 
     */
    fn_OpenCustInfoBs (item) {

      let lv_Vm      = this
      let cnsltNo    = ''
      let nmUiObject = {}

      if(this.pOrgDataMSPCM150M.cnslt === undefined || this.pOrgDataMSPCM150M.cnslt === null || this.pOrgDataMSPCM150M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPCM150M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId,        //고객채널 ID
        parentId : this.$options.screenId,   //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm,
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         return
      })

    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
 },// methods

}//export default


</script>