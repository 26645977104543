/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM151P
 * 화면 설명: 1건고객 > 고객검색
 * 접근권한: FC
 * 작 성 일: 2022.12.06
 * 작 성 자: 서영화 
 */
// 조직부분 고객쪽 퍼블이 계속 어긋나서 계약쪽 팝업 퍼블 부분 활용하였음
<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb80">
        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData151P" @selectResult="fn_SelectCnsltResult"/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="info-box">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column">
            <mo-text-field v-model="searchedCustNm" class="mb0" type="none" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <!-- 관심고객구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="mt20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">관심고객구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="inqrScCd.value" class="chip-type-wrap" solid primary>
                <mo-segment-button v-for="(item, idx) in inqrScCds" :key="idx" :value="item.value">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 고객구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="mt20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">고객구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="smCustScCd.value" class="chip-type-wrap" solid primary >
              <template  v-for="(item, idx) in smCustScCds">
                <mo-segment-button :key="idx" v-if="idx < 3" :value="item.value">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
            <mo-segment-wrapper v-model="smCustScCd.value" class="chip-type-wrap chip-ty1 mt10" solid primary>
              <template  v-for="(item, idx) in smCustScCds">
                <mo-segment-button :key="idx" v-if="idx >= 3" :value="item.value">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 상품 -->
        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="mt20 pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">상품</span>
          <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="smPrcd.value" class="chip-type-wrap" solid primary>
              <template v-for="(item, idx) in smPrcds">
                <mo-segment-button :key="idx" v-if="idx < 3" :value="item.value">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
            <mo-segment-wrapper v-model="smPrcd.value" class="chip-type-wrap chip-ty1 mt10 pb60" solid primary>
              <template v-for="(item, idx) in smPrcds">
                <mo-segment-button :key="idx" v-if="idx >= 3" :value="item.value">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import cmConstants     from '@/config/constants/cmConstants' 

export default {

  name: 'MSPCM151P', 
  screenId: 'MSPCM151P', 
  components: {    
    SearchCondOrgCM,
  },//componets,

  props:{
    pPage: String,            // 부모 페이지명
    pSearchedCustNm: String,  // 고객명
    pIsMngr: Boolean,         // 지점장여부
    pOrgData: {               // 컨설턴트 및 조직정보
      type: Object,
      default: {}
    },
    pInqrScCd: String,        // 관심고객구분
    pSmCustScCd: String,      // 고객구분
    pSmPrcd: String,          // 상품

    pSelectedItems: {         // 조회조건 결과 
      type: Object,
      default: {}
    }
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_ReturnVal: {},        // 리턴값
      isReturn: false,         // 리턴값 세팅여부 

      /** 
       * 사용자 정보 변수
       */ 
      lv_SelectOrg: {},        // 조직 및 담당 컨설턴트 컴포넌트 데이터 
      pOrgData151P: {},        // 조직정보 
      userInfo: {},            // 사용자정보 

      /** 
       * 조회 변수 
       */
      searchedCustNm: '',      // 고객명 
      inqrScCds: [],           // 관심고객구분 CODE-LIST
      inqrScCd: {},            // 선택된 관심고객구분 데이터
      smCustScCds: [],         // 고객구분 CODE-LIST 
      smCustScCd: {},          // 선택된 고객구분 데이터
      smPrcds: [],             // 상품 CODE-LIST 
      smPrcd: {},              // 선택된 상품 데이터

      selectedItems: {},       // 조회조건 결과

      /**
       * 제어변수
       */
      isCleared: false,        // 조회조건 초기화 여부 

    }
  },//data

  created () {
    
    window.vue.getStore('progress').dispatch('FULL')              // post 호출 전 Progress 타입 설정.
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 데이터 설정 

    /**
     * props 데이터 및 공통코드 설정
     */
    this.pOrgData151P   = this.pOrgData             
    this.searchedCustNm = this.pSearchedCustNm      
    this.inqrScCds      = cmConstants.ST_INQR_SC_CD
    this.smCustScCds    = cmConstants.SM_CUST_SC_CD
    this.smPrcds        = cmConstants.SM_PRDT_CD
    
    /**
     * 조회조건 결과 설정 
     */
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      // 선택지점, 선택사업부, 선택지역단, 컨설턴트, 고객명, 관심고객구분, 고객구분, 상품
      this.selectedItems = {
        hofOrgCd:       {title: '', text: '', idx: 1},           
        dofOrgCd:       {title: '', text: '', idx: 2},           
        fofOrgCd:       {title: '', text: '', idx: 3},           
        cnsltEno:       {title: '컨설턴트', text: '', idx: 4},    
        searchedCustNm: {title: '고객', text: '', idx: 5},       
        inqrScCd:       {title: '관심고객구분', text: '', idx: 6},
        smCustScCd:     {title: '고객구분', text: '', idx: 7},     
        smPrcd:         {title: '상품', text: '', idx:8},          
      }
    }else{
      this.selectedItems  = this.pSelectedItems 
    }

  },//created
  mounted () {
    
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 조회조건 선택값 설정 
    this.inqrScCd   = Object.assign({}, this.inqrScCds.filter((item) => item.value === this.pInqrScCd)[0])
    this.smCustScCd = Object.assign({}, this.smCustScCds.filter((item) => item.value === this.pSmCustScCd)[0])
    this.smPrcd     = Object.assign({}, this.smPrcds.filter((item) => item.value === this.pSmPrcd)[0])

  },//mounted

  watch: {

    /**
     * 선택된 관심고객구분 데이터를 저장한다.
     */
    'inqrScCd.value': function(val){
      let data =  this.inqrScCds.filter((item) => item.value === val)
      this.inqrScCd = Object.assign({}, data[0])
    },

    /**
     * 선택된 고객구분 데이터를 저장한다.
     */
    'smCustScCd.value': function(val){
      let data =  this.smCustScCds.filter((item) => item.value === val)
      this.smCustScCd = Object.assign({}, data[0])
    },

    /**
     * 선택된 상품 데이터를 저장한다.
     */
    'smPrcd.value': function(val){
      let data =  this.smPrcds.filter((item) => item.value === val)
      this.smPrcd = Object.assign({}, data[0])
    },

    /**
     * 영문 고객명 입력 시 대문자로 치환한다.
     */
    searchedCustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.searchedCustNm)) {
        if (englishOrKorean.test(this.searchedCustNm)) {
          this.searchedCustNm = this.searchedCustNm.toUpperCase()
        }
      }
    }

  },//watch

  computed: {},//computed

  methods: {

    /**
     * @description 조직 및 담당컨설턴트 변경 시, 전역변수에 세팅해준다.
     * @param       {Object} pData 조직 및 담당컨설턴트 정보 
     */
    fn_SelectCnsltResult(pData){
        this.lv_SelectOrg = pData
    },

    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){

      // 관리자 접근 시 조직정보 초기화 후, 재조회
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      this.pOrgData151P   = {}
      this.searchedCustNm = ''      
      this.inqrScCd       = Object.assign({}, this.inqrScCds[0])
      this.smCustScCd     = Object.assign({}, this.smCustScCds[0])
      this.smPrcd         = Object.assign({}, this.smPrcds[0])
      
    },
    
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal () {
      
      // 초기화 
      this.lv_ReturnVal = {} 

      // 조회조건 결과 설정
      this.fn_SetSelectedItem()

      // 리턴값 설정
      this.lv_ReturnVal.cnsltEno = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? this.lv_SelectOrg.cnslt.key  : ''   
      this.lv_ReturnVal.dofOrgCd = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' 
      this.lv_ReturnVal.fofOrgCd = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' 
      this.lv_ReturnVal.hofOrgCd = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : ''
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg 
      
      this.lv_ReturnVal.searchedCustNm = this.searchedCustNm.trim()   
      this.lv_ReturnVal.inqrScCd       = this.inqrScCd.value          
      this.lv_ReturnVal.smCustScCd     = this.smCustScCd.value        
      this.lv_ReturnVal.smPrcd         = this.smPrcd.value            
      this.lv_ReturnVal.selectedItems  = this.selectedItems          
      this.lv_ReturnVal.isCleared      = this.isCleared              

      // 리턴값 세팅여부 
      this.isReturn = true 
 
    },

    /**
     * @description 검색결과 데이터(조건이름)을 저장한다.
     *              초기 조건값과 현재 조건값이 동일하지 않을 때만 저장한다.
     */
    fn_SetSelectedItem(){
      
      if(this.$bizUtil.isEmpty(this.searchedCustNm.trim())  && (this.inqrScCd.value === this.inqrScCds[0].value) && 
          (this.smCustScCd.value === this.smCustScCds[0].value) && (this.smPrcd.value === this.smPrcds[0].value)){

        // 조직 컴포넌트 데이터가 존재할 경우
        if(!_.isEmpty(this.lv_SelectOrg)){
          if(this.userInfo.userId === this.lv_SelectOrg.cnslt.key){
            this.isCleared = true 
            return
          }
        }else{
          this.isCleared = true 
          return 
        }
        
      }
      
      this.isCleared = false 

      this.selectedItems.hofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.label : '' 
      this.selectedItems.dofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.label : '' 
      this.selectedItems.fofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.label : '' 
      this.selectedItems.cnsltEno.text = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? new Object(this.lv_SelectOrg.cnslt).label.split('(')[0].trim() : '' 

      this.selectedItems.searchedCustNm.text = this.searchedCustNm.trim()
      this.selectedItems.inqrScCd.text       = this.inqrScCd.text
      this.selectedItems.smCustScCd.text     = this.smCustScCd.text
      this.selectedItems.smPrcd.text         = this.smPrcd.text
        
    },

    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 리턴값 세팅
      this.fn_SetReturnVal() 

      if (this.isReturn) {
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal });
      }
      
    },
    
 },// methods

}//export default


</script>